import * as React from "react"
import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Layout from "../../components/layout"
import Seo from "../../components/seo.js"

import * as style from "../../styles/blog.module.scss"
import "../../styles/buttons.scss"

const Product = props => {
  const image = getImage(props.data.mdx.frontmatter.image)

  return (
    <Layout headOneOfPage={props.data.mdx.frontmatter.image_alt}>
      <Seo title={props.data.mdx.frontmatter.title} lang="hu" />
      <figure className={style.container}>
        <div className={style.imageContainer}>
          <GatsbyImage
            className={style.image}
            image={image}
            alt={props.data.mdx.frontmatter.image_alt}
          />
        </div>

        <div className={style.description}>
          <MDXRenderer>{props.data.mdx.body}</MDXRenderer>
        </div>
      </figure>
    </Layout>
  )
}
export const query = graphql`
  query ($id: String) {
    mdx(id: { eq: $id }) {
      frontmatter {
        title
        image_alt
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      body
    }
  }
`
export default Product
